<template>
    <a-modal class="acknowledgement-modal" visible :onCancel="handleCancel" :footer="null">
        <Acknowledgement ref="acknowledgement" :saleAcknowledgement="saleAcknowledgement"
            :readOnlyMode="readOnlyMode || !saleId" :saleId="SaleId" @validate="handleValidateAcknowledgement"
            @send="handleSendAcknowledgement">
        </Acknowledgement>
    </a-modal>
</template>

<script setup>
import { defineAsyncComponent, defineProps } from "vue";
import { useStore } from "vuex";

const {  dispatch } = useStore();

const props = defineProps(['saleId', 'saleAcknowledgement', 'readOnlyMode']);

const Acknowledgement = defineAsyncComponent(() => import("@/components/businessComponents/Acknowledgement.vue"));
const handleValidateAcknowledgement = async (acknowledgement) => {
    acknowledgement.saleId = props.saleId;
    dispatch("addCustomerPo", acknowledgement);
};
</script>

<style lang="scss">
.acknowledgement-modal{
.ant-modal-body{
    padding: 0px;
}
}
</style>